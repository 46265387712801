// import { Loading } from 'element-ui';
import {validatenull} from '@/echart/util'
import {checkUrl, getFunction} from '@/utils/utils'
// import {getAuth, getToken} from '@/utils/auth'
import { getToken } from "@/util/auth";
import axios from 'axios';
import website from "@/config/website";
import { baseUrl } from "@/config/env";
import { Base64 } from "js-base64";
import { Message } from "element-ui";
import {doRequest} from "@/util/doRequest"
window.$glob = {
    url: '',
    params: {},
    query: {},
    headers: {}
};

function getGlobParams() {
    var query = window.location.search.substring(1);
    query = query.split("&");
    query.forEach(ele => {
        var pair = ele.split("=");
        window.$glob.params[pair[0]] = pair[1]
    })
}
axios.defaults.baseURL = baseUrl;
//默认超时时间
axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
// let loadingInstance = '';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
        // loadingInstance = Loading.service({
        //   text: '拼命加载中',
        //   background: 'rgba(0,0,0,0)',
        //   spinner: 'el-icon-loading'
        // });
        getGlobParams();
        if (!checkUrl(config.url)) {
            config.url = window.$glob.url + config.url;
        }
        if (!validatenull(window.$glob.header)) {
            let header = getFunction(window.$glob.header)();
            config.headers = Object.assign(config.headers, header);
        }else{
            config.headers["Authorization"] = `Basic ${Base64.encode(
                `${website.clientId}:${website.clientSecret}`
              )}`;
        }

        //获取全局参数
        if (typeof (config.data) === 'object') {
            let data = {}
            if (!validatenull(window.$glob.query)) {
                data = getFunction(window.$glob.query)()
            }
            data = Object.assign(window.$glob.params, data)

            if (config.method === 'get') {
                config.params = Object.assign(config.params, data)
            } else if (config.method === 'post') {
                config.data = Object.assign(config.data, data)
            }
        }
        //headers中配置text请求
        if (config.text === true) {
            config.headers["Content-Type"] = "text/plain";
        }

        // const auth = getAuth()
        // if (auth) {
        //     config.headers["Authorization"] = `Basic ${auth}`;
        // }
        //让每个请求携带token
        // const token = getToken()
        // if (token) {
        //     config.headers["Coe-Auth"] = `bearer ${token}`;
        // }
        if (getToken()) {
            config.headers[website.tokenHeader] = "bearer " + getToken();
          }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
;
//HTTPrequest拦截
axios.interceptors.response.use( function(res){
    // loadingInstance.close();
    const status = res.data.code || res.status;
    const message = res.data.msg || res.data.error_description || "未知错误";
        //token过期刷新
        if(status == 401 ){
            return doRequest(res.config)
        }
        // 如果请求为非200否者默认统一处理
        if (status !== 200) {
            Message({
              message: message,
              type: "error"
            });
            return Promise.reject(new Error(message));
          }
    return res;
}, error => {
    // loadingInstance.close();
    return Promise.reject(new Error(error));
})

export default axios;
