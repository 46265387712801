import request from '../axios'
import store from '@/store'
import router from "../router";

export function doRequest(config) {
    //token刷新未授权登出
    console.info("url" + config.url)
    if (config.url === '/api/coe-auth/oauth/token') {
        return store.dispatch("permissionTip").then(() => {
            store.dispatch("FedLogOut").then(() => router.push({path: "/login"}));
        })
    }
    return new Promise((resolve) => {
        store.dispatch("refreshToken").then(() => {
            if (config.data && typeof config.data == 'string') {
                config.data = JSON.parse(config.data)
            }
            request({
                url: config.url.substr(4),
                method: config.method,
                params: config.params || '',
                data: config.data || ''
            }).then(res => resolve(res))
        })


    })
}
