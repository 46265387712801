/**
 * 全局配置文件
 */
export default {
    title: "KVDV数据大屏",
    logo: "KVDV",
    key: "kvdv", //配置主键,目前用于存储
    indexTitle: "KVDV",
    clientId: "datav", // 客户端id
    clientSecret: "l59AMYLox0q2G8oC", // 客户端密钥
    tenantMode: false, // 是否开启租户模式
    captchaMode: false, // 是否开启验证码模式
    tenantId: "000000", // 管理组租户编号
    scope: 3,
    encryptType: "RSA", //登录加密模式：MD5,AES,RSA
    // encryptKey: "SO2sUIhKZwU5AVvG7smVYZ4h7QdGaze5", //AES
    encryptKey: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC+qz3+KRxz7OJ//hrHW/Tt2zfLFMVtcL8yezonEhDwIjEaYm2uDnBm4CZWj/wZxsm/QGSgAfyiDkmuvf6vGf+2QNY8H52Jd3yDTFu0VB3anvGk0OUVo3Kq8e14bjwz/QaDb86041OGq6qOVmu52JEAfgOVpTHD06Zx13kFU60UkwIDAQAB",
    lockPage: "/lock",
    tokenTime: 3000,
    tokenHeader: "Coe-Auth",
    //http的status默认放行列表
    statusWhiteList: [],
    //配置首页不可关闭
    isFirstPage: false,
    fistPage: {
        label: "首页",
        value: "/wel/index",
        params: {},
        query: {},
        meta: {
            i18n: "dashboard"
        },
        group: [],
        close: false
    },
    //配置菜单的属性
    menu: {
        iconDefault: "iconfont icon-caidan",
        props: {
            label: "name",
            path: "path",
            icon: "source",
            children: "children"
        }
    },
    // 第三方系统授权地址
    authUrl: "http://localhost/coe-auth/oauth/render",
    // 流程设计器地址
    flowDesignUrl: "http://localhost:9999",
    // 报表设计器地址(cloud端口为8108,boot端口为80)
    reportUrl: "http://localhost:8108/ureport"
};
