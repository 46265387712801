import Vue from 'vue'
import ElementUI from 'element-ui'
import Avue from '@smallwei/avue'
import '@smallwei/avue/lib/index.css'
import axios from './axios'
import 'element-ui/lib/theme-chalk/index.css'
import dataV from '@jiaminghi/data-view'
import router from './router.js'
import website from '@/config/website'
import store from './store'
import App from './App.vue'
import './styles/common.scss'
import '@/utils/es6'
import '@/mock/'
//导入主题文件
import '@/theme/index.js'
import './permission' // 权限
Vue.config.productionTip = false
window.axios = axios
Vue.use(Avue, {
  size: 'mini'
})
Vue.prototype.$website = window.$website
// 加载website
Vue.prototype.website = website
Vue.use(ElementUI)
Vue.use(dataV)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
