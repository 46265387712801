import request from '../axios'
import website from "@/config/website";

export const loginByUsername = (
    tenantId,
    username,
    password,
    grantType,
    encryptType,
    type,
    key,
    code
) => request({
    url: "/coe-auth/oauth/token",
    method: "post",
    headers: {
        "Tenant-Id": tenantId,
        "Captcha-Key": key,
        "Captcha-Code": code
    },
    params: {
        tenantId,
        username,
        password,
        user_scope: website.scope,
        grant_type: grantType,
        encrypt_type: encryptType,
        scope: "all",
        type
    }
});

export const loginBySocial = (tenantId, source, code, state) =>
    request({
        url: "/coe-auth/oauth/token",
        method: "post",
        headers: {
            "Tenant-Id": tenantId
        },
        params: {
            tenantId,
            source,
            code,
            state,
            grant_type: "social",
            scope: "all"
        }
    });

export const refreshToken = (refresh_token, tenantId) =>
    request({
        url: "/coe-auth/oauth/token",
        method: "post",
        headers: {
            "Tenant-Id": tenantId
        },
        params: {
            tenantId,
            refresh_token,
            grant_type: "refresh_token",
            scope: "all"
        }
    });

export const registerGuest = (form, oauthId) =>
    request({
        url: "/coe-user/register-guest",
        method: "post",
        params: {
            tenantId: form.tenantId,
            name: form.name,
            account: form.account,
            password: form.password,
            oauthId
        }
    });

export const getCaptcha = () =>
    request({
        url: "/coe-auth/oauth/captcha",
        method: "get"
    });

export const logout = () =>
    request({
        url: "/coe-auth/oauth/logout",
        method: "get"
    });

export const getUserInfo = () =>
    request({
        url: "/coe-auth/oauth/user-info",
        method: "get"
    });

export const sendLogs = list =>
    request({
        url: "/coe-auth/oauth/logout",
        method: "post",
        data: list
    });

export const clearCache = () =>
    request({
        url: "/coe-auth/oauth/clear-cache",
        method: "get"
    });

export const userMenuTree = () =>
    axios.get("/coe-sys/menu/datavUserMenuTree");

export const userPerms = () =>
    axios.get("/coe-sys/menu/datavUserPerms")
